import React from "react";
import Wood from "../../assets/internal/expertise/wood.png";
import Ria from "../../assets/internal/expertise/RIA.png";
import Neon from "../../assets/internal/expertise/Neon.png";
import Atwell from "../../assets/internal/expertise/Atwell.png";
import engie from "../../assets/internal/expertise/engie.png";
import mott from "../../assets/internal/expertise/mott.png";
import grupoMexico from "../../assets/internal/expertise/grupoMexico.png";
import hanmattan from "../../assets/internal/expertise/harmattan.png";
import dnv from "../../assets/internal/expertise/dnv.png";
import rog from "../../assets/internal/expertise/rog.png";
import { NavLink } from "react-router-dom";

const Brand = () => {
  const brands = [
    {
      id: 1,
      url_imagen: Wood,
      alt: "Wood",
      href_value: "/",
    },
    {
      id: 2,
      url_imagen: Ria,
      alt: "Ria",
      href_value: "/",
    },
    {
      id: 3,
      url_imagen: Neon,
      alt: "Neon",
      href_value: "/",
    },
    {
      id: 4,
      url_imagen: Atwell,
      alt: "Atwell",
      href_value: "/",
    },
    {
      id: 1,
      url_imagen: engie,
      alt: "engie",
      href_value: "/",
    },
    {
      id: 1,
      url_imagen: mott,
      alt: "mott",
      href_value: "/",
    },
    {
      id: 1,
      url_imagen: grupoMexico,
      alt: "grupoMexico",
      href_value: "/",
    },
    {
      id: 1,
      url_imagen: hanmattan,
      alt: "hanmattan",
      href_value: "/",
    },
    {
      id: 1,
      url_imagen: dnv,
      alt: "dnv",
      href_value: "/",
    },
    {
      id: 1,
      url_imagen: rog,
      alt: "rog",
      href_value: "/",
    },
  ];
  return (
    <div className="brands">
      <ul>
        {brands.map((brand) => (
          <li>
            <NavLink to={brand.href_value} title={brand.alt}>
              <img src={brand.url_imagen} alt={brand.alt} />
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Brand;

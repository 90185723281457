import React from "react";
import welcome from "../../assets/Home/welcome.png";
import "../../styles/Home/Welcome.scss";
import { NavLink } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="welcome">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="img-container">
              <img src={welcome} alt="Welcome" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <div className="txt-container">
              <div className="title">
                <h3>
                  <span>About Wisergy</span>
                </h3>
              </div>
              <div className="txt">
                <p>
                  Through a global network of clients and partners, we have
                  supported different sectors across the entire lifecycle of
                  their projects.
                </p>
                <p>
                  The local experience offered by Wisergy as well as continuous
                  development and training of its team is the core of its
                  success.
                </p>
                <p>
                  Our multidisciplinary team is assigned with high priority to
                  deliver our client's needs with utmost professionalism.
                </p>
                <div>
                  <NavLink
                    className="about-link"
                    title={"Read More About Us"}
                    to={"/about-us"}
                  >
                    Read More About Us
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import Slider from "../components/Slider";
import Merch from "../components/Merch";
import Planning from "../components/Planning";
import Team from "../components/Team";
import Specs from "../components/Specs";
import Ctas from "../components/Ctas";
import { useEffect } from "react";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main>
        <Slider />
        <Merch />
        <Specs />
        <Ctas />
        <Planning />
        <Team />
      </main>
    </>
  );
}

import React from 'react';
import BlogPost from './NewsPage';
import '../styles/Blog.scss';

const BLOG = () => {
  const posts = [
    {
        title: 'News 1',
        content: 'Content of news 1...',
        link: 'https://www.example.com/news1'
      },
      {
        title: 'News 2',
        content: 'Content of news 2...',
        link: 'https://www.example.com/news2'
      },
      {
        title: 'New model to identify land eligibility, calculate LCOE for utility-scale PV',
        content: 'Created by scientists in Poland, the model is based on GIS and can be adapted to different market conditions. The researchers applied it to the Polish market and found that 3.61% of the country’s available land can host utility-scale PV systems.',
        link: 'https://www.pv-magazine.com/2023/10/23/new-model-to-identify-land-eligibility-calculate-lcoe-for-utility-scale-pv/?utm_source=dlvr.it&utm_medium=linkedin'
      },
    // Agrega más noticias aquí
  ];

  return (
    <div className="blog-container">
      <h1 className="blog-title">News Blog</h1>
      {posts.map((post, index) => (
        <BlogPost
          key={index}
          title={post.title}
          content={post.content}
          link={post.link}
        />
      ))}
    </div>
  );
};

export default BLOG;
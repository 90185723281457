import React from "react";
import HeroRibbon from "./HeroRibbon";

export default function HeroShot({
  background,
  alt,
  pageTitle1,
  pageTitle2,
  bgColor,
}) {
  return (
    <div className="hero-shot">
      <div className="image-container">
        <img src={background} alt={alt} />
      </div>
      <HeroRibbon
        pageTitle1={pageTitle1 ?? "Hero Shot"}
        pageTitle2={pageTitle2 ?? ""}
        bgColor={bgColor ?? "blue-bg"}
      />
    </div>
  );
}

import React, { useEffect } from "react";
import graduatedHeroShot from "../assets/main/graduate-hero-shot.jpg";
import graduates1 from "../assets/main/graduates1.png";
import "../styles/componentsStyles/Jobs.scss";
import "../styles/componentsStyles/Merch.scss";
import HeroShot from "./HeroShot";
import { NavLink } from "react-router-dom";

export default function Graduates() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="jobs-page">
      <HeroShot
        alt={"Graduates"}
        background={graduatedHeroShot}
        pageTitle1={"Graduates & Interns"}
        bgColor={"orange-bg"}
      />

      <div className="container info-container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="jobs-image-container">
              <img src={graduates1} alt="Jobs" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <p>
              Before you jump in into the real work, we offer a mentoring
              program. We welcome motivated students with different areas of
              expertise to work on projects linked to the energy sector.
            </p>
            <p>
              The internship program is open on an on-going basis. Students
              enrolled at a fully accredited degree programme "Bachelor, Master,
              PhD" during the entire duration of their internship can send their
              application to our Contact. Selected interns are on-boarded in our
              office in La Paz, BCS. <NavLink to="/contact">Contact</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import { React, useState, useEffect } from "react";
import "../styles/header.scss";
import logo from "../assets/header/logo.png";
import { NavLink } from "react-router-dom";

export default function Header() {
  const disclaimer = "Under Construction";
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  return (
    <div className="header-container container">
      <header className={`${sticky ? "sticky" : ""}`}>
        <div className="row">
          <div className="col-8 col-sm-6 col-md-2">
            <div className="logo-container">
              <NavLink to={"/"}>
                <img src={logo} alt="Wisergy" />
              </NavLink>
            </div>
          </div>
          <div className="col-4 col-sm-6 col-md-10">
            <nav className="navbar navbar-expand-md navbar-light">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink className="nav-link" to={"/"}>
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to={"/about-us"}
                        title="About Us"
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        title={"Our Expertise"}
                        to={"/our-expertise"}
                      >
                        Our Expertise
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        title={"Services"}
                        to={"/services"}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Careers
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <NavLink
                            className="nav-link"
                            title={"Jobs"}
                            to={"/jobs"}
                          >
                            Jobs Openings
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="nav-link"
                            title={"Careers"}
                            to={"/careers"}
                          >
                            Graduates & Interns
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        title={"Research and Innovation"}
                        to={"/r+i"}
                      >
                        R+I
                      </NavLink>
                    </li>
                    {/*<li className="nav-item">
                      <NavLink className="nav-link" title={disclaimer} to={"/"}>
                        News
                      </NavLink>
                    </li>*/}
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        title={disclaimer}
                        to={"/contact"}
                      >
                        Contact
                      </NavLink>
                    </li>
                    {/*<li className="nav-item blog-mobile-link">
                      <NavLink
                       className="nav-link"
                       title={disclaimer} 
                       to={"/blog"}
                       >
                        Blog
                      </NavLink>
                  </li>*/}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}

import React from "react";

export default function NotFound() {
  return (
    <div className="not-found">
      404 Not Found
      <br />
      Work In Progress ...
    </div>
  );
}

import React from "react";
import instagram from "../assets/main/intagram.png";
import twitter from "../assets/main/twiter-x-logo-white.png";
import linkedin from "../assets/main/linkedin.png";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <p className="copyright">
              Wisergy Engineering S. de R. L. de C. V.
            </p>
          </div>
          <div className="col-12 col-sm-6">
            <div className="links-container">
              <div className="link">
                <a
                  href="https://www.linkedin.com/company/wisergy-engineering-sc/"
                  target="_blank"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
              <div className="link">
                <a href="https://www.instagram.com/wisergyeng/" target="_blank">
                  <img src={instagram} alt="Instagram" />
                </a>
              </div>
              <div className="link">
                <a href="https://twitter.com/WisergyEng" target="_blank">
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from "react";
import "../../styles/Home/Locations.scss";
import { NavLink } from "react-router-dom";

const Locations = () => {
  return (
    <div className="locations">
      <div className="container">
        <div className="upper-title row">
          <div className="col-6">
            <h3>We are here!</h3>
          </div>
          <div className="col-6">
            <p className="link">
              <NavLink to="/our-expertise">See Our Expertise</NavLink>
            </p>
          </div>
        </div>
        <div className="txt-container">
          <h3>
            Launch <br />
            Region
          </h3>
          <p>
            Local presence in <span className="line-style">10</span> countries.
          </p>
          <p>
            Wide network of <span className="line-style">local</span>
            <br />
            <span className="line-style">and international partners.</span>
          </p>
          <p>
            Local development of projects
            <br /> facilitated by our presence in{" "}
            <span className="line-style">
              Central
              <br /> America, South
              <br />
              America, Mexico, South Africa, Europe
              <br />
              and Australia.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Locations;

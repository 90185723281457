import React from 'react';
import '../styles/NewsPage.scss';

const BlogPost = ({ title, content, link }) => {
  return (
    <div className="blog-post">
      <h2>{title}</h2>
      <p>{content}</p>
      <p>
        <a href={link} target="_blank" rel="noopener noreferrer">Read more</a>
      </p>
      
    </div>
  );
};

export default BlogPost;
import React from "react";
import energy from "../assets/main/energy-cta.png";
import advisory from "../assets/main/advisory-cta.png";
import transportation from "../assets/main/transportation-cta.png";
import enviroment from "../assets/main/enviroment-cta.png";
import water from "../assets/main/water-cta.png";
import w from "../assets/main/w-cta.png";
import "../styles/componentsStyles/Ctas.scss";
import { NavLink } from "react-router-dom";

const Ctas = () => {
  const ctas = [
    {
      id: 1,
      url_imagen: energy,
      titulo: "Energy",
      alt: "Energy",
      href_value: "/",
    },
    {
      id: 2,
      url_imagen: advisory,
      titulo: "Advisory",
      alt: "Advisory",
      href_value: "/",
    },
    {
      id: 3,
      url_imagen: transportation,
      titulo: "Transportation",
      alt: "Transportation",
      href_value: "/",
    },
    {
      id: 4,
      url_imagen: enviroment,
      titulo: "Enviroment",
      alt: "Enviroment",
      href_value: "/",
    },
    {
      id: 5,
      url_imagen: water,
      titulo: "Water",
      alt: "Water",
      href_value: "/",
    },
    {
      id: 6,
      url_imagen: w,
      alt: "w",
      titulo: "Wisergy",
      href_value: "/",
    },
  ];
  return (
    <div className="ctas">
      <div className="container row">
        <div className="col-12 col-lg-6 ctas-container">
          <ul>
            {ctas.map((service) => (
              <div className="list-element" key={service.id}>
                <li>
                  <NavLink to={service.href_value}>
                    <img src={service.url_imagen} alt={service.alt} />
                    <span className="cta-title">{service.titulo}</span>
                  </NavLink>
                </li>
              </div>
            ))}
          </ul>
        </div>
        <div className="ctas-txt-container col-12 col-lg-6">
          <h3 className="team-title">
            <span>How...</span>
          </h3>
          <p>
            Through a global network of clients and partners, we have supported
            different markets across the entire lifecycle of their projects. In
            the short and medium term, Wisergy expects to increase its services
            and expand to other sectors.
          </p>
          <p>
            The local experience offered by Wisergy as well as continuous
            development and training of its team is the core of its success.
          </p>
          <NavLink className="cta-link-txt" to="/about-us">
            Read More
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Ctas;

import Header from "../components/Header";
import HeroShot from "../components/Home/HeroShot";
import HomeSpecs from "../components/Home/HomeSpecs";
import Welcome from "../components/Home/Welcome";
import Locations from "../components/Home/Locations";
import CustomFooter from "../components/Home/CustomFooter";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <main>
        <HeroShot />
        <HomeSpecs />
        <Welcome />
        <Locations />
      </main>
      <CustomFooter />
    </>
  );
}

import React from "react";
import logo from "../../assets/Home/wisergy-logo-footer.png";
import solarPanel from "../../assets/Home/footer-1.png";
import Footer from "../../components/Footer";
import "../../styles/Home/CustomFooter.scss";
import { NavLink } from "react-router-dom";

export default function CustomFooter() {
  return (
    <div className="custom-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="title-container">
              <div className="title">
                <span>
                  Be part of
                  <br />
                  the new era
                </span>
              </div>
              <p className="footer-link">
                <NavLink to="/contact" title="Read More About Us">
                  Contact Us
                </NavLink>
              </p>
            </div>
            <div className="logo">
              <NavLink to="/" title={"Wisergy Home Page"}>
                <img src={logo} alt="Wisergy Logo" />
              </NavLink>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="img-container">
              <img src={solarPanel} alt="Solar Panel" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useState } from "react";
import TeamMember from "./TeamMember";
import teamList from "./members_list";

export default function TeamMemberList() {
  const [_teamList, setTeamList] = useState([...teamList]);
  return (
    <div className="team-list">
      {_teamList.map((team_member) => (
        <TeamMember team_member={team_member} key={team_member.id} />
      ))}
      
    </div>
  );
}

import React from "react";
import "../../styles/Home/HeroShot.scss";
import { NavLink } from "react-router-dom";

export default function HeroShot() {
  return (
    <div className="HeroShot">
      <div className="hero-txt container">
        <h4>Who we are</h4>
        <div className="title">
          <span>
            The future <br />
            of energy
          </span>
        </div>
        <p className="hero-link">
          <NavLink to="/about-us" title="Read More About Us">
            Read More About Us
          </NavLink>
        </p>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import contactHeroShot from "../assets/internal/contact/contact-hero-shot.png";
import email from "../assets/internal/contact/email-icon.png";
import map from "../assets/internal/contact/map-icon.png";


import "../styles/componentsStyles/Contact.scss";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact-page">
      <div className="hero-shot">
        <img src={contactHeroShot} alt="Contact Hero Shot" />
      </div>
      <div className="pageContent container">
        <div className="title">
          <h3>
            <span>Contact us</span>
          </h3>
        </div>
        <div className="content row">
          <div className="col-12 col-md-6">
            <div class="info-title">
              <div className="icon-container">
                <img src={email} alt="Email Icon" />
              </div>
              <div className="email-container">
                <h3>CONTACT US</h3>
                <p>For Business Development Inquiries</p>
                <div className="txt">
                  <p>Luis Velasquez</p>
                  <p>Executive Director</p>
                  <p>
                    <a href="mailto:luis.velasquez@wisergy-eng.com">
                      luis.velasquez@wisergy-eng.com
                    </a>
                  </p>
                </div>
                <div className="txt">
                  <h3>OTHER INQUIRIES</h3>
                  <p>
                    <a href="mailto:contact@wisergy-eng.com">
                      contact@wisergy-eng.com
                    </a>
                  </p>
                  <p>
                    <a href="tel:+17343358687">+1 734 335 8687</a>
                  </p>
                  <p>
                    <a href="tel:+526122043614">+52 612 146 6053</a>
                  </p>
                </div>
                {/*<div className="social-media-txt">
                  <p>
                    FOLLOW US
                    <br />
                    ON SOCIAL MEDIA
                  </p>
                  <div className="social-media">
                    <div className="links-container">
                      <div className="link">
                        <a
                          href="https://www.linkedin.com/company/wisergy-engineering-sc/"
                          target="_blank"
                        >
                          <img src={linkedin} alt="linkedin" />
                        </a>
                      </div>
                      <div className="link">
                        <a
                          href="https://www.instagram.com/wisergyeng/"
                          target="_blank"
                        >
                          <img src={instagram} alt="Instagram" />
                        </a>
                      </div>
                      <div className="link">
                        <a
                          href="https://twitter.com/WisergyEng"
                          target="_blank"
                        >
                          <img src={twitter} alt="Twitter" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div class="info-title second-info-title">
              <div className="icon-container">
                <img src={map} alt="Map Icon" />
              </div>
              <div className="email-container">
                <h3>LOCATIONS</h3>
                <div className="txt">
                  <p>
                    <a
                      className="link"
                      href="https://goo.gl/maps/UtK35BnmH2uXAUCn9"
                      target="blank"
                    >
                      Wisergy USA
                      <br />
                      2028 East Ben White Boulevard Suite 240,
                      <br />
                      Austin, TX 78741, United States.
                      <br />
                    </a>
                  </p>
                </div>
                <div className="txt">
                  <p>
                    <a
                      className="link"
                      href="https://goo.gl/maps/rZKbWGF8f35fjwD86"
                      target="blank"
                    >
                      Wisergy Mexico
                      <br />
                      460, Ave. Alvaro Obregón, Cornejo L-206,207,210,
                      <br />
                      211 Building, La Paz, BCS.
                      <br />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import energy from "../assets/internal/services/services-1.png";
import icon1 from "../assets/internal/services/icon-1.png";
import icon2 from "../assets/internal/services/icon-2.png";
import icon3 from "../assets/internal/services/icon-3.png";
import icon4 from "../assets/internal/services/icon-4.png";
import icon5 from "../assets/internal/services/icon-5.png";
import transmission from "../assets/internal/services/services-2.png";
import advisory from "../assets/internal/services/services-3.png";

import diagram from "../assets/internal/services/diagram-bess.png";

import "../styles/internal/Services.scss";

const Expertise = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="services-container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="img-container">
            <img src={energy} alt="" />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="txt">
            <h3 className="page-title">
              <span>Energy</span>
            </h3>
            <p>
              Our Engineers have more than{" "}
              <b>15 years of experience in the energy industry.</b> They are
              certified to carry out P&C engineering, substation design, and
              SCADA for thermoelectric, combined cycles, hydro, solar and wind
              projects.
            </p>
            <p className="info-title">
              <b>Our services include:</b>
            </p>
            <div className="row icons-row">
              <div className="col-12 col-sm-2">
                <img className="icons" src={icon1} alt="Energy Icon 1" />
              </div>
              <div className="col-12 col-sm-10">
                <p>
                  Visual inspection of electrical substations (according to NETA
                  ATS standards).
                </p>
              </div>
            </div>
            <div className="row icons-row">
              <div className="col-12 col-sm-2">
                <img className="icons" src={icon2} alt="Energy Icon 2" />
              </div>
              <div className="col-12 col-sm-10">
                <p>
                  Root cause analysis for high and medium voltage failures at
                  substation equipment (transformers, feeders, switchgears,
                  zigzag transformers, FACTS, CT's, VT's, surge arresters,
                  capacitors, reactors, circuit breakers and disconnectors) and
                  support to the SCADA system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="renewable-container">
        <div className="container">
          <h3 className="page-title">
            <span>Renewable Energy</span>
          </h3>
          <p>
            We work across the entire lifecycle of the projects, from
            feasibility, greenfield and designing to implementing process
            improvement solutions.
          </p>
          <p>
            <b>Our services include:</b>
          </p>
          <div className="row">
            <div className="col-12 col-lg-1">
              <img className="icons" src={icon3} alt="Renewable Icon 1" />
            </div>
            <div className="col-12 col-lg-11">
              <h2>Solar Power</h2>
              <p>
                Construction monitoring, brownfield and greenfield visits,
                retrofitting and repowering, I-V Curves trace, power ratio,
                solar yield assessment, ground and insulation resistance test,
                thermography testing, root cause analysis for low voltage
                failures (solar field), construction, supervision and visual
                inspection for civil works, design, commissioning, O&M,
                communication and SCADA support, PVsist/pvDesign (pick shaving,
                self-consumption, weak grid recovery) and ETAP/DigSILENT
                simulation (Short circuit analysis, protection coordination
                analysis, arc-flash analysis, load flow, grid code analysis).
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-1">
              <img className="icons" src={icon4} alt="Renewable Icon 1" />
            </div>
            <div className="col-12 col-lg-11">
              <h2>Wind Power</h2>
              <p>
                Construction monitoring, visual inspection, design,
                commissioning, O&M,ETAP/DigSILENT simulation (Short circuit
                analysis, protection coordination analysis, arc-flash analysis,
                load flow, grid code).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="battery-storage container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h3 className="page-title">
              <span>Battery Energy Storage Solution</span>
            </h3>
            <p>
              Energy storage play and important role in improving energy
              efficiency to achieve the decarbonization of energy and transport
              sector. The integration of renewable energies in the grid requires
              the incorporation of storage to maximize energy yield, provide
              grid stability for critical facilities, limit variability of
              output to the grid, and provide ancillary services to the network
              operator.
            </p>
            <p>
              <b>Our services include:</b>
            </p>
            <p>
              Construction monitoring, design, commissioning, O&M,
              PVsist/pvDesign simulation (pick shaving, self-consumption, weak
              grid recovery) and ETAP/DigSILENT simulation (Short circuit
              analysis, protection coordination analysis, arc-Flash analysis,
              load flow, grid code analysis).
            </p>
          </div>
          <div className="col-12 col-md-5 offset-md-1">
            <div className="img-container">
              <img src={diagram} alt="Diagram BESS" />
            </div>
          </div>
        </div>
      </div>
      <div className="row transmission-container">
        <div className="col-12 col-lg-6 img-container-col-left">
          <div className="img-container">
            <img src={transmission} alt="" />
          </div>
        </div>
        <div className="col-12 col-lg-6 container-col-right">
          <div className="txt">
            <h3 className="page-title">
              <span>Transmission and Distribution</span>
            </h3>
            <p>
              We provide transmission and distribution services in new and
              existing: power substations, transmission and distribution
              systems. Our engineer provide expertise from engineering and
              construction to maintenance.
            </p>
            <p className="info-title">
              <b>Our services include:</b>
            </p>
            <div className="row icons-row">
              <div className="col-12 col-sm-2">
                <img className="icons" src={icon5} alt="Energy Icon 1" />
              </div>
              <div className="col-12 col-sm-10">
                <p>
                  Construction monitoring, design, reviews and engineering,
                  ETAP/DigSILENT simulation (Short circuit analysis, protection
                  coordination analysis , control, communication and SCADA,
                  arc-flash analysis, load flow, grid code analysis).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row Inspection-container">
        <div className="col-12 col-lg-6 container-col-left">
          <div className="txt">
            <h3 className="page-title">
              <span>Inspection and Testing</span>
            </h3>
            <p>
              The energy sector growth is moving fast. Our qualified engineers
              can undertake testing services and perform inspections globally to
              meet international compliance regulations-
            </p>
            <p className="info-title">
              <b>Our services include:</b>
            </p>
            <div className="list">
              <ul>
                <li>Asset condition and performance assessment</li>
                <li>Supervision of inspections and testing</li>
                <li>Root Cause Analysis</li>
                <li>Visual inspection</li>
                <li>I-V Curve testing</li>
                <li>Cable Insulation resistance test</li>
                <li>Grounding resistance test</li>
                <li>Energy quality analysis</li>
                <li>VLF testing (NOT US market)</li>
                <li>Cable testing</li>
                <li>Fault localization in power systems</li>
                <li>Vibration testing</li>
                <li>Thermography</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 img-container-col-right">
          <div className="img-container">
            <img src={transmission} alt="" />
          </div>
        </div>
      </div>
      <div className="row advisory-container">
        <div className="col-12 col-lg-6 img-container-col-left">
          <div className="img-container">
            <img src={advisory} alt="" />
          </div>
        </div>
        <div className="col-12 col-lg-6 container-col-right">
          <div className="txt">
            <h3 className="page-title">
              <span>Advisory Services</span>
            </h3>
            <p>
              Our multidisciplinary team provides advisory services based on
              client demands, new market. With our network of partners, we share
              best practices, expertise and learnings to enable superior
              services and results to our clients.
            </p>
            <p className="info-title">
              <b>Our services include:</b>
            </p>
            <div className="list">
              <ul>
                <li>Technical advisory</li>
                <li>Financial advisory</li>
                <li>Market studies</li>
                <li>Technical and financial due diligence</li>
                <li>Feasibility studies</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expertise;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./layouts/Home";
import PaginaInterna from "./layouts/PaginaInterna";
import "./styles/Home.scss";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home></Home>} />
        <Route path="/:page_type" element={<PaginaInterna />} />
        <Route path="/:page_type" element={<PaginaInterna />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />} //this is a way to redirect
        />
      </Routes>
    </Router>
  );
};

export default App;

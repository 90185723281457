import React from "react";
import energy from "../../assets/Home/icon-energy-v2.png";
import battery from "../../assets/Home/icon-battery-storage-v2.png";
import renewable from "../../assets/Home/icon-renewable-energy-v2.png";
import transmission from "../../assets/Home/icon-transmission-and-distribution-v2.png";
import inspection from "../../assets/Home/icon-inspection-and-testing-v2.png";
import advisory from "../../assets/Home/icon-advisory-services-v2.png";
import "../../styles/Home/HomeSpecs.scss";
import { NavLink } from "react-router-dom";

const HomeSpecs = () => {
  return (
    <div className="home-specs container">
      <h3 class="page-title">
        <span>What we do?</span>
      </h3>
      <div className="list-container">
        <ul>
          <li>
            <div className="image-container">
              <img src={energy} alt="Gender Equality" />
            </div>
            <div className="txt">
              <h3>Energy</h3>
              <p>
                Our Engineers have more than{" "}
                <b>15 years of experience in the energy industry.</b> They are
                certified to carry out P&C engineering, substation design,
                measurement, and SCADA for thermoelectric, combined cycles,
                hydro, solar and wind projects.
              </p>
              <p className="link">
                <NavLink title={"Read More"} to={"/services"}>
                  Read More
                </NavLink>
              </p>
            </div>
          </li>
          <li>
            <div className="image-container">
              <img src={battery} alt="Gender Equality" />
            </div>
            <div className="txt">
              <h3>Battery Storage</h3>
              <p>
                Energy storage plays an important role in improving energy
                efficiency to achieve the decarbonization of the energy ad
                transport sectors.
              </p>
              <p className="link">
                <NavLink title={"Read More"} to={"/services"}>
                  Read More
                </NavLink>
              </p>
            </div>
          </li>
          <li>
            <div className="image-container">
              <img src={renewable} alt="Gender Equality" />
            </div>
            <div className="txt">
              <h3>Renewable Energy</h3>
              <p>
                We work across the entire lifecycle of the projects, from
                feasibility and greenfield to designing and implementing
                solutions.
              </p>
              <p className="link">
                <NavLink title={"Read More"} to={"/services"}>
                  Read More
                </NavLink>
              </p>
            </div>
          </li>
          <li>
            <div className="image-container">
              <img src={transmission} alt="Gender Equality" />
            </div>
            <div className="txt">
              <h3>Transmission and Distribution</h3>
              <p>
                We provide transmission and distribution services in new and
                existing power substations and transmission and distribution
                systems. Our engineers provide expertise for engineering,
                construction, and maintenance.
              </p>
              <p className="link">
                <NavLink title={"Read More"} to={"/services"}>
                  Read More
                </NavLink>
              </p>
            </div>
          </li>
          <li>
            <div className="image-container">
              <img src={inspection} alt="Gender Equality" />
            </div>
            <div className="txt">
              <h3>Inspection and Testing</h3>
              <p>
                The energy sector is  growing fast. Our qualified engineers
                can undertake testing services and perform inspections globally
                to meet international compliance regulations.
              </p>
              <p className="link">
                <NavLink title={"Read More"} to={"/services"}>
                  Read More
                </NavLink>
              </p>
            </div>
          </li>
          <li>
            <div className="image-container">
              <img src={advisory} alt="Gender Equality" />
            </div>
            <div className="txt">
              <h3>advisory Services</h3>
              <p>
                Our multidisciplinary team provides advisory services based on
                client demands, new market. With our network of partners, we
                share best practices, expertise and learnings to enable superior
                services and results to our clients.
              </p>
              <p className="link">
                <NavLink title={"Read More"} to={"/services"}>
                  Read More
                </NavLink>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeSpecs;

import { Link } from "react-router-dom";
import "../styles/componentsStyles/_team.scss";
import TeamMemberList from "./TeamMemberList";
import { useEffect } from "react";

export default function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="team container">
      <h3 className="team-title">
        <span>The Team</span>
      </h3>
      <p>
        Our multidisciplinary group of professionals are enthusiastic about
        identifying clients’ needs and matching them to appropriate solutions.
        Our young leaders are trained with care and dedication in a daily basis
        by seniors, making sure they develop the skills and knowledge, in a
        friendly environment, for a complete insertion to the industry.
      </p>
      <TeamMemberList />
      <div className="careers">
        <h3>JOIN US!</h3>
        <Link to="/careers" className="button">
          CAREERS
        </Link>
      </div>
    </div>
  );
}

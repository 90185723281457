import React, { useEffect } from "react";
import Brand from "../components/internal/Brand";
import tableImage from "../assets/internal/expertise/expertise-1.png";
import "../styles/internal/Expertise.scss";
const Expertise = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cells = [
    {
      id: 1,
      country: "México",
      tec: "PV",
      size: "5,315",
    },
    {
      id: 2,
      country: "South Africa",
      tec: "PV",
      size: "3,608",
    },
    {
      id: 3,
      country: "Panama",
      tec: "Wind",
      size: "354",
    },
    {
      id: 4,
      country: "USA",
      tec: "PV",
      size: "612",
    },
    {
      id: 5,
      country: "USA",
      tec: "Wind",
      size: "300",
    },
    {
      id: 6,
      country: "Dominican Republic",
      tec: "PV",
      size: "192.5",
    },
    {
      id: 7,
      country: "Jamaica",
      tec: "PV",
      size: "51.5",
    },
    {
      id: 8,
      country: "Australia",
      tec: "PV",
      size: "192",
    },
    {
      id: 9,
      country: "Poland",
      tec: "PV",
      size: "60",
    },
    {
      id: 10,
      country: "El Salvador",
      tec: "PV",
      size: "10",
    },
    {
      id: 11,
      country: "Brazil",
      tec: "Wind",
      size: "500",
    },
  ];
  return (
    <div className="expertise-container">
      <div className="expertise">
        <div className="container">
          <div className="upper-title row">
            <div className="col-12">
              <h3>We are here!</h3>
            </div>
            <div className="col-6"></div>
          </div>
          <div className="txt-container">
            <h3>
              Launch <br />
              Region
            </h3>
            <p>
              Local presence in <span className="line-style">10</span>{" "}
              countries.
            </p>
            <p>
              Wide network of <span className="line-style">local</span>
              <br />
              <span className="line-style">and international partners.</span>
            </p>
            <p>
              Local development of projects
              <br /> facilitated by our presence in{" "}
              <span className="line-style">
                Central
                <br /> America, South
                <br />
                America, Mexico, South Africa, Europe
                <br />
                and Australia.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="disclaimer container">
        <p>
          <span>Wisergy</span> has provided consultancy services for more than{" "}
          <span>
            70 solar
            <br />
            and wind generation sites worldwide
          </span>
          , with an overall installed
          <br />
          capacity of 9.1 GW.
        </p>
      </div>
      <div className="table-container">
        <div className="row">
          <div className="col-12 col-md-6">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Country</th>
                  <th scope="col">Technology</th>
                  <th scope="col">Size (MW)</th>
                </tr>
              </thead>
              <tbody>
                {cells.map((cell) => (
                  <tr key={cell.id}>
                    <th scope="row">{cell.country}</th>
                    <td>{cell.tec}</td>
                    <td>{cell.size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="txt">
              The greenhouse gas emissions avoided
              <br />
              by these projects correspond to approximately
              <br /> <b>161,105,502 tons of CO2.</b>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <div className="image-container">
              <img src={tableImage} alt="Solar Panel" />
            </div>
          </div>
        </div>
      </div>
      <div className="brands-container container">
        <p>
          Wisergy's
          <b>
            strategic location in one of the leading countries in the energy
            sector
          </b>
          has allowed it to expand and serve other markets with ease.
        </p>
        <Brand />
      </div>
    </div>
  );
};

export default Expertise;

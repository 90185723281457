import React from 'react'
import '../styles/componentsStyles/Specs.scss'
import collaboration from '../assets/main/collaboration.png'
import gender from '../assets/main/gender-equality.png'
import sustainbility from '../assets/main/sustainbility.png'
import youth from '../assets/main/youth.png'
const Specs = () => {
    return (
        <div className="specs-section container">
            <p>
                Wisergy Engineering S.C. was founded in March 2021 in La Paz, Baja Sur, Mexico; and in the USA through its subsidiary: Wisergy USA Inc. in April 2022. Our locations ensure fast attention to our clients’ needs and ease of communication between countries in North and Latin America.
            </p>
            <p>
            We follow a policy of fairness and respect, which is part of our long-term strategy. Our philosophy is based on four key concepts:
            </p>
            <div className="list-container">
                <ul>
                    <li>
                        <div className="image-container">
                            <img src={gender} alt="Gender Equality" />
                        </div>
                        <div className="txt">
                            <h3>
                                Gender Equality
                            </h3>
                            <p>
                                It is important for us to actively promote the contribution of women to engineering. For this reason, we help develop their careers through mentoring and advice.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="image-container">
                            <img src={sustainbility} alt="Gender Equality" />
                        </div>
                        <div className="txt">
                            <h3>
                                Sustainability
                            </h3>
                            <p>
                                From clean sources of energy, we support the generation of electricity that leads to a synergistic development with the natural, social, and economic environment.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="image-container">
                            <img src={youth} alt="Gender Equality" />
                        </div>
                        <div className="txt">
                            <h3>
                                Youth
                            </h3>
                            <p>
                                At Wisergy we carry out the mission of boosting the potential of youth to play a direct role in the transformation of the energy and transport sectors.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="image-container">
                            <img src={collaboration} alt="Gender Equality" />
                        </div>
                        <div className="txt">
                            <h3>
                                Collaboration
                            </h3>
                            <p>
                                Our multidisciplinary team, clients and partners work together to develop suitable solutions for energy and mobility projects around the world.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
  
  export default Specs;

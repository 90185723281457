import React, { useEffect } from "react";
import HeroShot from "../components/HeroShot";
import ListOrangeDots from "../components/ListOrangeDots";

export default function ResearchInnovation({ pageClass }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const itemsList = [
    "Energy storage",
    "Economy & markets",
    "Systems modeling",
    "Environmental impacts",
    "Water & sanitation",
    "Smart grids & energy transmission",
    "Urban planning",
    "Electrical substations",
    "Regulations & policies",
    "Mobility",
    "Social impacts",
  ];
  return (
    <div className={`${pageClass} research-innovation`}>
      <HeroShot
        alt={"Research & Innovation"}
        background={
          "/images/r+i/businessmen-businesswomen-meeting-brainstorming-ideas.jpg"
        }
        pageTitle1={"Research"}
        pageTitle2={"& Innovation Unit "}
        bgColor={"blue-bg"}
      />
      <div className="organigrama">
        <img src="/images/r+i/organigrama.png" alt="Organization" />
      </div>

      <p>
        As part of Wisergy’s energy transition and sustainable development
        efforts, the Research and Innovation Unit (RIU) has been appointed since
        2022 to perform high-quality research and innovation projects.
      </p>
      <p>
        By sharing knowledge with the general public and mobilizing our global
        partnerships, we aim to help build more sustainable and inclusive
        societies.
      </p>
      <p>Our research, development, and innovation lines of action:</p>
      <ListOrangeDots itemsList={itemsList} />
      <div className="bottom-image-container">
        <img src="/images/ip-bottom.png" alt="Wisernegy" />
      </div>
    </div>
  );
}

const teamList = [
  {
    id: 2,
    icon: "/images/juan.png",
    name: "Juan Carlos Castro",
    title: "SENIOR RENEWABLE ENERGY CONSULTANT",
    resume_en: `Juan Carlos is a qualified electrical and mechanical engineer that has worked 
    in the utility-scale renewable energy industry for over 5 years. He specializes 
    in the engineering, design, testing, and inspection of Solar and Eolic energy 
    systems at all stages, providing clients with bankable reports. His 
    achievements include optimizing 900MW+ of solar systems, increasing the 
    efficiency and ROI of the projects. He is a PVsyst certified professional who 
    enjoys sharing his knowledge and experience whenever he is given the 
    chance`,
    resume_es: `Juan Carlos es un ingeniero mecánico y eléctrico que ha trabajado en la 
    industria de energías renovables a gran escala durante más de 5 años. Se 
    especializa en la ingeniería, diseño, prueba e inspección de sistemas de energía 
    solar y eólica en todas las etapas, brindando a los clientes informes 
    financiables. Sus logros incluyen la optimización de más de 900MW de 
    sistemas solares, aumentando la eficiencia y el ROI de los proyectos. Es un 
    profesional certificado por PVsyst que disfruta compartiendo su conocimiento 
    y experiencia cada vez que tiene la oportunidad`,
  },
  {
    id: 3,
    icon: "/images/connie.png",
    name: "Connie Rivera",
    title: "ADMINISTRATIVE ASSISTANT",
    resume_en: `Connie has worked in the administration area of international business for 
    more than 7 years. Throughout her career she has developed procedures and 
    methods for supporting the technical tasks timely and accurately, always 
    applying her strong multi-tasking, organizational, and time management skills.
    As Administrative Assistant, she provides administrative support to four 
    departments by coordinating work schedules, liaising with internal teams and 
    external partners, procuring the resources, and managing various tasks 
    assigned by executives.`,
    resume_es: `Connie ha trabajado en el área de administración de negocios internacionales 
    por más de 7 años. A lo largo de su carrera, ha desarrollado procedimientos y 
    métodos para respaldar las tareas técnicas de manera oportuna y precisa, 
    siempre aplicando sus sólidas habilidades multitarea, organizativas y de 
    gestión del tiempo.
    Como asistente administrativa, brinda apoyo administrativo a cuatro 
    departamentos mediante la coordinación de los horarios de trabajo, el enlace 
    con los equipos internos y los socios externos, la adquisición de recursos y la 
    gestión de diversas tareas asignadas por los ejecutivos`,
  },
  {
    id: 4,
    icon: "/images/laura.png",
    name: "Laura Araiza",
    title: "JUNIOR RENEWABLE ENERGY CONSULTANT",
    resume_en: `Laura is a junior engineer with a passion for renewable energy sources and 
    their impact to our societies. She is set to help make energy systems more 
    efficient and profitable.
    She has demonstrated proficiency in the implementation of the NEC and 
    Mexican environmental legislations, as well as managing engineering teams 
    through multiple projects at once`,
    resume_es: `Laura es una ingeniera junior apasionada por las fuentes de energía renovable 
    y su impacto en nuestras sociedades. Ella está lista para ayudar a que los 
    sistemas de energía sean más eficientes y rentables.
    Ha demostrado competencia en la implementación de la NEC y las 
    legislaciones ambientales mexicanas, así como en la gestión de equipos de 
    ingeniería a través de múltiples proyectos a la vez`,
  },
  {
    id: 5,
    icon: "/images/enrique.png",
    name: "Enrique Orihuela",
    title: "SPECIALIST IN HIGH VOLTAGE AND SUBSTATIONS",
    resume_en: `Enrique is a qualified electrical engineer who has worked in the renewable 
    energy industry for over 10 years.
    Having worked on the engineering and commissioning of 400MW+ solar 
    projects all the way from LV to interconnection point; designed, supervised, 
    and built 350MVA+ of substations; and performed protection, control, and 
    grid code compliance for solar and wind projects at all stages, he is able to 
    provide clients with the required expertise and confidence for data-driven 
    decision-making`,
    resume_es: `Enrique es un ingeniero eléctrico calificado que ha trabajado en la industria de 
    las energías renovables durante más de 10 años. Habiendo trabajado en la 
    ingeniería y puesta en marcha de proyectos solares de más de 400MW desde 
    BT hasta el punto de interconexión; diseñado, supervisado y construido
    350MVA+ de subestaciones; y realizado la protección, el control y el 
    cumplimiento del código de red para proyectos solares y eólicos, tiene la 
    capacidad de brindar a los clientes la experiencia y la confianza necesarias 
    para la toma de decisiones basada en datos`,
  },
  {
    id: 6,
    icon: "/images/victoria.png",
    name: "Victoria Romero",
    title: "JUNIOR RENEWABLE ENERGY CONSULTANT",
    resume_en: `Victoria is a junior engineer looking to directly participate in the transition to 
    the use of renewable energy to ensure a sustainable future.
    She has demonstrated proficiency in monitoring large-scale PV system repair 
    and maintenance projects. She assists in technical due diligence processes, 
    reviewing evidence of repair work, making and reviewing technical reports, 
    and maintaining client relationships`,
    resume_es: `Victoria es una ingeniera junior que busca participar directamente en la 
    transición al uso de energías renovables para asegurar un futuro sostenible.
    Ella ha demostrado competencia en el monitoreo de proyectos de reparación 
    y mantenimiento de sistemas fotovoltaicos de gran escala. Apoya en los 
    procesos de debida diligencia técnica, en la revisión de evidencias de trabajos 
    de reparación, haciendo y revisando reportes técnicos, y manteniendo 
    relaciones con los clientes`,
  },
  {
    id: 7,
    icon: "/images/carlos.png",
    name: "Carlos Mijares",
    title: "SPECIALIST IN PROTECTION, CONTROL, MEASUREMENT, AND SCADA",
    resume_en: `Carlos is a mechatronics engineer with over 7 years of experience in the 
    energy sector, who specializes in the communications among generation 
    systems, substations, and SCADAs.
    He has performed inspections and testing to operational generation sites in 
    Mexico, which accumulate 400MW+.
    With a self-taught multidisciplinary approach to problem solving, his 
    management skills, and an easygoing personality, he is able to collaborate 
    with international clients, suppliers, grid operators, and subcontractors to 
    achieve common goals while saving time and reducing yield losses.`,
    resume_es: `Carlos es ingeniero en mecatrónica con más de 7 años de experiencia en el 
    sector energético, especializado en las comunicaciones entre sistemas de 
    generación, subestaciones y SCADAs.
    Ha realizado inspecciones y pruebas a sitios de generación en operación en 
    México, los que acumulan más de 400MW.
    Con un enfoque multidisciplinario autodidacta para la resolución de 
    problemas, sus habilidades de gestión y una personalidad amigable, puede 
    colaborar con clientes, proveedores, operadores de red y subcontratistas 
    internacionales para lograr objetivos comunes mientras ahorra tiempo y 
    reduce las pérdidas de energía`,
  },
];
export default teamList;

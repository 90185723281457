import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams } from "react-router";
import AboutUs from "../components/AboutUs";
import Jobs from "../components/Jobs";
import Graduates from "../components/Graduates";
import Contact from "../components/Contact";
import Expertise from "../layouts/Expertise";
import Services from "../layouts/Services";
import Blog from "../components/Blog";
import News from "../components/NewsPage";

import NotFound from "../components/NotFound";
import TeamMemberProfile from "../components/TeamMemberProfile";
import ResearchInnovation from "../pages/ResearchInnovation";

export default function PaginaInterna() {
  const { page_type } = useParams();

  const getPageContent = (page) => {
    if (page.includes("team-")) {
      return <TeamMemberProfile />;
    }
    switch (page) {
      case "about-us":
        return <AboutUs />;
      case "r+i":
        return <ResearchInnovation pageClass={"internal-page white-footer"} />;

      case "jobs":
        return <Jobs />;

      case "careers":
        return <Graduates />;

      case "our-expertise":
        return <Expertise />;

      case "services":
        return <Services />;

      case "contact":
        return <Contact />;
      case "blog":
        return <Blog />;
      case "newspaper":
        return <News />;
      case "hours":
        return <div>Hours content</div>;

      default:
        return <NotFound />;
    }
  };
  return (
    <>
      <Header />
      {getPageContent(page_type)}
      <Footer />
    </>
  );
}

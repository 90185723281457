import React, { useEffect } from "react";
import jobsHeroShot from "../assets/main/jobs-hero-shot.jpg";
import jobs1 from "../assets/main/jobs-1.png";
import "../styles/componentsStyles/Jobs.scss";
import "../styles/componentsStyles/Merch.scss";
import merchImage from "../assets/main/jobs-merch.png";
import { NavLink } from "react-router-dom";

export default function Jobs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const merchTxt = "Job Openings";
  return (
    <div className="jobs-page">
      <div className="hero-shot">
        <img src={jobsHeroShot} alt="Jobs" />
      </div>
      <div className="merchContainer">
        <div className="image-container">
          <img src={merchImage} alt="About Us" />
        </div>
        <div className="txt-container">
          <p className="txt">{merchTxt}</p>
        </div>
      </div>
      <div className="container info-container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="jobs-image-container">
              <img src={jobs1} alt="Jobs" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <p>
              Be part of a safer future. Energy transition start with immediate
              actions. At Wisergy we are committed to bring the best talent.
            </p>
            <p>
              All our job postings are made through Linkedin. For inquiries
              about other methods to apply or you think we should meet you
              please see our section{" "}
              <NavLink title={"Contact"} to={"/contact"}>
                Contact
              </NavLink>
            </p>
            <p>
              Job search start by clicking{" "}
              <a href="mailto:contact@wisergy-eng.com"> here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import { React } from "react";
import { Link } from "react-router-dom";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
import "../styles/componentsStyles/_teamMember.scss";

export default function TeamMember({ team_member }) {
  return (
    <Link to={`/team-${team_member.id}`}>
      <div key={team_member.id}>
        <img src={team_member.icon} alt={team_member.name} />
        <span>
          <span>{team_member.name}</span>
          <span>{team_member.title}</span>
        </span>
      </div>
    </Link>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import teamList from "./members_list";

export default function TeamMemberProfile() {
  const { page_type } = useParams();
  const [profileData, setProfileData] = useState();
  useEffect(() => {
    const personId = page_type.split("-")[1];
    const person = teamList.filter(
      (person) => person.id === parseInt(personId)
    );
    setProfileData(person[0]);
  }, []);

  return (
    <>
      {profileData && (
        <div className="about-us container">
          <div>
            <div className="image-container">
              <img src={profileData?.icon} alt={profileData?.name} />
            </div>
            <h3>
              <span>{profileData?.name}</span>
              <span>{profileData?.title}</span>
            </h3>
            <p>{profileData?.resume_en}</p>
          </div>
          <div className="image-container">
            <img src="/images/ip-bottom.png" alt="Wisernegy" />
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";

export default function ListOrangeDots({ itemsList }) {
  return (
    <div className="list-orange-dots">
      {itemsList?.map((item) => {
        return (
          <div>
            <span>{item}</span>
          </div>
        );
      })}
    </div>
  );
}

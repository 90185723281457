import perform from "../assets/main/PERFORMS.png";
import puzzle from "../assets/main/puzzle.png";
import location from "../assets/main/location.png";
import "../styles/componentsStyles/Planning.scss";
import { useEffect } from "react";

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const servicesList = [
    {
      id: 1,
      text: "Ensure financial sustainability",
    },
    {
      id: 2,
      text: "Ensure customer satisfaccion",
    },
    {
      id: 3,
      text: "Ensure employee satisfaction",
    },
    {
      id: 4,
      text: "Maximize the impact in the community",
    },
    {
      id: 5,
      text: "Grow in the capabilities and result of the company",
    },
    {
      id: 6,
      text: "Continuosly improve internal processes",
    },
  ];
  return (
    <div className="services container">
      <h3 className="team-title">
        <span>Bottom-up Planning</span>
      </h3>
      <p>Planning starts from the engineers on the front lines to:</p>
      <div className="services-list">
        {servicesList.map((service) => (
          <div className="text-container" key={service.id}>
            <p>{service.text}</p>
          </div>
        ))}
      </div>
      <p>
        Inclusive planning <b>delivers the best results</b> for everyone, and
        for our clients too.
      </p>
      <div class="delivers-container row">
        <div class="col-12 col-sm-6">
          <p class="first-row-txt">
            We Perform our work
            <br /> by customizing
            <br /> every service according
            <br /> to our clients needs.
          </p>
        </div>
        <div class="col-12 col-sm-6">
          <div className="img-container">
            <img src={perform} alt="Perform" />
          </div>
        </div>
      </div>

      <div className="delivers-container row">
        <div className="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-3">
          <p class="txt-second-row">
            We Seek <br /> <span className="line-style">efficiency</span>
            <br /> in our <br /> operations <br />{" "}
            <span className="second-line-style">The inside view...</span>
          </p>
        </div>
        <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
          <div className="img-container">
            <img src={puzzle} alt="Perform" />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <p className="puzzle-txt">
            R+I is cross sectoral. We build value by researching, designing, and
            innovating in new products and services.
          </p>
        </div>
      </div>

      <div className="delivers-container row">
        <div className="col-12 col-sm-6">
          <p className="wisergy-txt">
            Wisergy Works
            <br /> with Local Partners.
            <br /> We follow a Geocentric
            <br /> Trasnational Strategy.
          </p>
        </div>
        <div class="col-12 col-sm-6">
          <div className="img-container">
            <img src={location} alt="Perform" />
          </div>
        </div>
      </div>
    </div>
  );
}

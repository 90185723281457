import React from "react";
import sliderImage from "../assets/main/carousel.jpg";
import sliderImage2 from "../assets/main/about-slider/beautiful-alternative-energy-plant-with-solar-panels.jpg";
import sliderImage3 from "../assets/main/about-slider/engineer-working-on-checking-and-maintenance-equipment-at-wiring-on-plc-cabinet.jpg";
import sliderImage4 from "../assets/main/about-slider/part-of-highvoltage-substation-with-switches-and-disconnectors.jpg";
import "../styles/componentsStyles/slider.scss";

const Slider = () => {
  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={sliderImage}
            className="d-block w-100"
            alt="beautiful View Of the Wind Turbines"
          />
        </div>
        <div className="carousel-item">
          <img
            src={sliderImage2}
            className="d-block w-100"
            alt="beautiful View Of the Wind Turbines"
          />
        </div>
        <div className="carousel-item">
          <img
            src={sliderImage3}
            className="d-block w-100"
            alt="beautiful View Of the Wind Turbines"
          />
        </div>
        <div className="carousel-item">
          <img
            src={sliderImage4}
            className="d-block w-100"
            alt="beautiful View Of the Wind Turbines"
          />
        </div>
      </div>
      <button
        className="carousel-control carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-icon carousel-control-prev-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-icon carousel-control-next-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Slider;

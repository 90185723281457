const HeroRibbon = ({ pageTitle1, pageTitle2, bgColor }) => {
  //
  return (
    <div className={`txt-container ${bgColor}`}>
      <h1
        className={`txt ${
          pageTitle1 !== "" && pageTitle2 !== "" ? "two-lines" : ""
        } `}
      >
        <span>{pageTitle1 ?? ""}</span>
        <span>{pageTitle2 ?? ""}</span>
      </h1>
    </div>
  );
};

export default HeroRibbon;

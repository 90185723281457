import React from 'react'
import '../styles/componentsStyles/Merch.scss'
import merchImage from '../assets/main/merch-image.png'

const Merch = () => {
    const merchTxt = "About Us"
    return (
        <div className="merchContainer">
            <div className="image-container"><img src={merchImage} alt="About Us"/></div>
            <div className="txt-container">
                <p className="txt">{merchTxt}</p>
            </div>
        </div>
    );
};
  
  export default Merch;
  